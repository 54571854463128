import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon, GridParagraph } from "@mostlycode/ui"
import ResponsiveImage from "components/ResponsiveImage"
import { getIconFromUrl } from "utils/iconResolver"
import { resolveImage } from "utils/imageResolver"

const StyledHeroSection = styled.section`
  position: relative;
  min-height: 20rem;
  z-index: 100;
  background: ${(props) => props.theme.colors.white};

  &:before {
    position: absolute;
    z-index: 150;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    display: block;
    content: "";
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    min-height: 50vh;
    max-height: 108rem;
  `}
`

const StyledGraphics = styled(GridParagraph)`
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;

  ${(props) => (props.useFractions ? "max-width: 1200px; margin: 0 auto;" : "")}

  ${(props) => (props.alignPhone ? `align-content: ${props.alignPhone};` : "")}

  ${(props) => props.theme.media.tablet_portrait_up`
    ${
      props.alignTabletPortrait
        ? `align-content: ${props.alignTabletPortrait};`
        : ""
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    ${
      props.alignTabletLandscape
        ? `align-content: ${props.alignTabletLandscape};`
        : ""
    }
  `}

`

const StyledResponsiveImage = styled(ResponsiveImage)`
  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

const StyledIcon = styled.span`
  display: flex;
  ${(props) =>
    props.justifyPhone ? `justify-content: ${props.justifyPhone};` : ""}
  ${(props) =>
    props.alignPhone ? `align-items: ${props.alignPhone};` : ""}
  svg {
    ${(props) =>
      props.sizePhone.split("*")[0]
        ? `width: ${props.sizePhone.split("*")[0]};`
        : ``}
    ${(props) =>
      props.sizePhone.split("*")[1]
        ? `height: ${props.sizePhone.split("*")[1]};`
        : ``}
  }

  ${(props) => props.theme.media.tablet_portrait_up`

    ${props.justifyPortrait ? `justify-content: ${props.justifyPortrait};` : ""}
    ${props.alignPortrait ? `align-items: ${props.alignPortrait};` : ""}

    ${
      props.sizePortrait
        ? `
          svg {
            ${
              props.sizePortrait.split("*")[0]
                ? `width: ${props.sizePortrait.split("*")[0]};`
                : ``
            }
            ${
              props.sizePortrait.split("*")[1]
                ? `height: ${props.sizePortrait.split("*")[1]};`
                : ``
            }
          }
        `
        : ``
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    ${
      props.justifyLandscape
        ? `justify-content: ${props.justifyLandscape};`
        : ""
    }

    ${props.alignLandscape ? `align-items: ${props.alignLandscape};` : ""}

    ${
      props.sizeLandscape
        ? `
          svg {
            ${
              props.sizeLandscape.split("*")[0]
                ? `width: ${props.sizeLandscape.split("*")[0]};`
                : ``
            }
            ${
              props.sizeLandscape.split("*")[1]
                ? `height: ${props.sizeLandscape.split("*")[1]};`
                : ``
            }
          }
        `
        : ``
    }

  `}
`

const StyledLogoIcon = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 4rem 2rem 2rem;
  position: relative;
  z-index: 100;
  background: ${(props) => props.theme.colors.white};

  svg {
    width: 100%;
    height: 6rem;
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    svg {
      width: 100%;
      height: auto;
    }
  `}
`

function HeroSection({ ...props }) {
  const {
    hero_image,
    imageSharp,
    items,
    mobile_grid,
    tablet_portrait_grid,
    tablet_landscape_grid,
    graphics_container,
    align_content,
  } = props
  const [
    alignPhone,
    alignTabletPortrait,
    alignTabletLandscape,
  ] = align_content.split(",")
  const image = resolveImage(hero_image, imageSharp)
  return (
    <>
      <StyledLogoIcon>
        <Icon icon="logo-med-payoff" />
      </StyledLogoIcon>
      <StyledHeroSection graphics_container={graphics_container}>
        {image && (
          <StyledResponsiveImage
            img={image}
            imgProps={{
              style: {
                display: "block",
                width: "100%",
              },
            }}
          />
        )}
        <StyledGraphics
          useFractions={graphics_container}
          gridPhone={mobile_grid}
          gridTabletPortrait={tablet_portrait_grid}
          gridTabletLandscape={tablet_landscape_grid}
          alignPhone={alignPhone}
          alignTabletPortrait={alignTabletPortrait}
          alignTabletLandscape={alignTabletLandscape}
        >
          {items.map((x, i) => {
            const {
              graphic,
              grid_position,
              sizes,
              justify_graphic,
              align_graphic,
            } = x
            const [phone, portrait, landscape] = sizes
              ? sizes.split(",")
              : "".split(",")
            const [
              justifyPhone,
              justifyPortrait,
              justifyLandscape,
            ] = justify_graphic ? justify_graphic.split(",") : "".split(",")
            const [
              alignGraphicPhone,
              alignGraphicPortrait,
              alignGraphicLandscape,
            ] = align_graphic ? align_graphic.split(",") : "".split(",")
            const icon = getIconFromUrl(graphic.url)
            return (
              <StyledIcon
                style={{ gridArea: grid_position }}
                sizePhone={phone}
                sizePortrait={portrait}
                sizeLandscape={landscape}
                justifyPhone={justifyPhone}
                justifyPortrait={justifyPortrait}
                justifyLandscape={justifyLandscape}
                alignPhone={alignGraphicPhone}
                alignPortrait={alignGraphicPortrait}
                alignLandscape={alignGraphicLandscape}
                key={`gfx_${i}`}
              >
                <Icon icon={icon} />
              </StyledIcon>
            )
          })}
        </StyledGraphics>
      </StyledHeroSection>
    </>
  )
}

export default HeroSection

HeroSection.defaultProps = {}

HeroSection.propTypes = {}
