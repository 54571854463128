import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import ResponsiveImage from "components/ResponsiveImage"
import { resolveImage } from "utils/imageResolver"

const StyledCustomHeroSection = styled.section`
  .image {
    position: relative;

    .flower-overlay {
      position: absolute;
      top: 4rem;
      left: 50%;
      transform: translate3d(-50%, 0, 0);

      svg {
        width: 31.6rem;
        height: 26.7rem;
      }

      .overlay-content {
        text-align: center;
        position: absolute;
        margin-top: 50%;
        width: 100%;
        transform: translate3d(0, -50%, 0);
        h1 {
          line-height: 1;
          letter-spacing: 1.62px;
          margin-bottom: 0;
          small {
            display: block;
            font-size: 1.4rem;
          }
        }

        p {
          max-width: 60%;
          margin: 1rem auto 0;
          line-height: 1;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.62px;
    overflow: hidden;
    padding-top: 4rem;
    background: ${(props) => props.theme.colors.primary_accent};
    position: relative;
    padding-bottom: 4rem;

    p {
      margin-top: 4rem;
      margin-bottom: -2rem;
      font-size: 2.1rem;
      letter-spacing: 6px;
    }

    .wellness {
      width: 28rem;
      height: 8rem;
    }

    .logos {
      width: 24rem;
      height: 5rem;
    }

    .flower-bg {
      width: 24rem;
      height: 24rem;
      fill: ${(props) => props.theme.colors.white};
      opacity: 0.25;
      position: absolute;
      bottom: -4rem;
      right: 4rem;
      transform: translate3d(50%, 0, 0);
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    margin-top: -8.2rem;
    .image {
      flex: 1 1 50%;

      .flower-overlay {
        top: 11.4rem;
      }
    }

    .content {
      flex: 1 1 50%;

      p {
        margin-top: 8rem;
        margin-bottom: -2rem;
        font-size: 2.6rem;
        letter-spacing: 6px;
      }

      .wellness {
        width: 64.5rem;
        height: 15rem;
      }

      .logos {
        width: 32rem;
        height: 7rem;
      }

      .flower-bg {
        width: 40rem;
        height: 40rem;
        fill: ${(props) => props.theme.colors.white};
        opacity: 0.25;
        position: absolute;
        bottom: -8rem;
        right: 0;
        transform: translate3d(50%, 0, 0);
      }
    }
  `}
`

const StyledLink = styled.a`
  margin: 4rem 0 0;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  border-radius: 4.6rem;
  line-height: 1.5;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.4px;
  font-size: 1.7rem;
  font-family: ${(props) => props.theme.fontStacks.bodyBold};
  padding: 1.5rem 2.5rem;
  display: inline-block;
  z-index: 20;
  position: relative;
`

function CustomHeroSection({ children, image, imageSharp, ...props }) {
  const resolvedImage = resolveImage(image, imageSharp)

  return (
    <StyledCustomHeroSection>
      <article className="image">
        <ResponsiveImage img={resolvedImage} />
        <div className="flower-overlay">
          <div className="overlay-content">
            <h1>
              <small>Dine mærker er </small>guld værd!
            </h1>
            <p>Opnå store besparelser når de indløses</p>
          </div>
          <Icon icon="pink-blomst" />
        </div>
      </article>
      <article className="content">
        <Icon className="wellness" icon="wellness-blomst" />
        <p>Tid til selvforkælelse</p>
        <Icon className="logos" icon="tid-til-selvforkaelelse" />
        <StyledLink href="#beurer">Læs mere</StyledLink>
        <Icon className="flower-bg" icon="blomst" />
      </article>
    </StyledCustomHeroSection>
  )
}

export default CustomHeroSection

CustomHeroSection.defaultProps = {}
CustomHeroSection.propTypes = {
  children: PropTypes.node.isRequired,
}
