import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import ProductPrice from "components/Product/Price"
import ResponsiveImage from "components/ResponsiveImage"
import { resolveImage } from "utils/imageResolver"

const StyledCustomProductsSection = styled.section`
  padding: 2rem 2rem 4rem;
  display: grid;
  grid-row-gap: 2rem;
  margin-top: -4rem;
  .product_group {
    padding: 2rem;
    background: ${(props) => props.theme.colors.primary_accent};
    svg {
      margin: 0 auto 2rem;
      display: block;
      width: 28.5rem;
      height: 10rem;
    }

    h3 {
      font-family: ${(props) => props.theme.fontStacks.bodyBold};
      font-size: 1.3rem;
      line-height: 1.5;
      margin-bottom: 0;
      margin-top: 2rem;

      &:first-of-type {
        margin: 0;
      }
    }

    .product {
      font-size: 2.4rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4rem;

      .product_price {
        flex: 0 1 15rem;
      }

      figure {
        padding: 0;
        margin: 0;
        flex: 1 1 15rem;
        /* height: 30rem; */
        position: relative;
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    width: 104rem;
    margin: 0 auto 6.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
    padding: 0;

    .products {
      display: grid;

      &.baylis {

        grid-template-columns: 1fr 1fr;

        .product {
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
          align-items: center;
        }
      }

      .product {
        margin-bottom: 0;
      }
    }
  `}
`

const StyledProductImage = styled.div`
  padding: 0;
  margin: 0;
  flex: 1 1 15rem;
  /* height: 30rem; */
  position: relative;
`

function CustomProductsSection({ items, ...props }) {
  const cacharel = items.filter((p) => p.product_key === "cacharel")
  const baylis = items.filter((p) => p.product_key === "baylis")

  function renderImage({ image, imageSharp }) {
    const resolvedImage = resolveImage(image, imageSharp)
    return (
      <StyledProductImage className="img">
        <ResponsiveImage img={resolvedImage} />
      </StyledProductImage>
    )
  }

  return (
    <StyledCustomProductsSection>
      <article className="product_group">
        <Icon className="cacharel" icon="cacharel-logo-sort" />
        <div className="products">
          <article className="product">
            <figure>{renderImage(cacharel[0])}</figure>
            <div className="product_price">
              <h3>2 stk. håndklæder 50 x 100 cm</h3>
              <ProductPrice
                currency="DKK"
                regular_price={14000}
                current_price_formatted={Math.round(5900 / 100)}
                current_price={5900}
              />
              <h3>Cacharel håndklæde 70 x 140 cm</h3>
              <ProductPrice
                currency="DKK"
                regular_price={13900}
                current_price_formatted={Math.round(5900 / 100)}
                current_price={5900}
              />
            </div>
          </article>
        </div>
      </article>
      <article className="product_group">
        <Icon className="baylis" icon="baylis-logo-sort" />
        <div className="products baylis">
          <article className="product">
            <div className="product_price">
              <h3>Baylis &amp; Harding duftlys</h3>
              <ProductPrice
                currency="DKK"
                regular_price={5000}
                current_price_formatted={Math.round(5000 / 100)}
                current_price={5000}
              />
            </div>
            <figure>{renderImage(baylis[0])}</figure>
          </article>
          <article className="product">
            <div className="product_price">
              <h3>Baylis &amp; Harding håndsæbe</h3>
              <ProductPrice
                currency="DKK"
                regular_price={3300}
                current_price_formatted={Math.round(2000 / 100)}
                current_price={2000}
              />
            </div>
            <figure>{renderImage(baylis[1])}</figure>
          </article>
        </div>
      </article>
    </StyledCustomProductsSection>
  )
}

export default CustomProductsSection

CustomProductsSection.defaultProps = {}
CustomProductsSection.propTypes = {
  children: PropTypes.node.isRequired,
}
