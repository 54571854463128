import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import Video from "./"

const StyledVideoRepeater = styled.section`
  background: linear-gradient(to bottom, #eee, #fff);
  position: relative;
  z-index: 200;
  padding: 2rem 2rem 4rem;

  .inner__pre {
    margin-top: 4rem;
    margin-bottom: 4rem;
    h2 {
      font-size: 2.4rem;
    }

    p {
      font-size: 2.1rem;
    }
  }

  .videos {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 4rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    .inner__pre, .videos {
      width: 96rem;
      margin: 0 auto;
    }

    .inner__pre {
      h2 {
        font-size: 3.2rem;
      }

      p {
        font-size: 2.1rem;
      }
    }

    .videos {
      margin-top: 4rem;
      grid-template-columns: repeat(2, 1fr);grid-column-gap: 4rem;
    }
  `}
`

function VideoRepeater({
  video_columns,
  videos_title,
  videos_content,
  items,
  ...props
}) {
  return (
    <StyledVideoRepeater columns={video_columns}>
      <div className="inner__pre">
        <Content content={videos_title} />
        <Content content={videos_content} />
      </div>
      <div className="videos">
        {items.map((video, i) => {
          return <Video key={`video_${i}`} {...video} />
        })}
      </div>
    </StyledVideoRepeater>
  )
}

export default VideoRepeater

VideoRepeater.defaultProps = {}
VideoRepeater.propTypes = {
  children: PropTypes.node,
}
