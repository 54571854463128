import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import styled from "styled-components"
import { post } from "axios"

const StyledCompetitionForm = styled.div`
  width: 100%;
  h3 {
    margin-bottom: 0;
    margin-top: 2em;
    text-align: left;
  }

  .gigya-form {
    a {
      color: ${(props) => props.theme.colors.primary};
    }
    a:hover {
      color: #666;
    }
    .gigya-composite-control {
      margin-top: 15px;
    }
    .gigya-input-text {
      width: 100%;
      padding: 1.5rem 1.5rem;
    }
    .gigya-composite-control-textbox .gigya-label {
      display: none;
    }
    [data-translation-key="LABEL_31031982353358850_LABEL"] {
      font-size: 14px;
      line-height: 1.5 !important;
      white-space: break-spaces;
      margin-top: 1rem;
      display: block;
    }
    .menu-toggle,
    button,
    .ast-button,
    .ast-custom-button,
    .button,
    input#submit,
    input[type="button"],
    input[type="submit"],
    input[type="reset"] {
      background-color: ${(props) => props.theme.colors.primary};
      color: #ffffff !important;
      border: none;
      padding: 1rem 2rem;
    }
    .gigya-composite-control.gigya-composite-control-checkbox.one-required.gigya-terms-error {
      border: 0px;
      padding: 0px;
      border-radius: 0px;
    }
    .gigya-screen .gigya-error-msg.gigya-error-msg-active {
      font-size: 14px !important;
    }
    label.text-required-field {
      font-size: 12px;
      color: #000;
      display: block;
    }
    .comeptition-question,
    .competition-question {
      margin-bottom: 15px;
    }
  }
`

function CompetitionForm({ children, ...props }) {
  const screensetFrameRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [retries, setRetries] = useState(0)

  async function loadScreenset() {
    window.gigya.accounts.showScreenSet({
      lang: "da",
      containerID: "screenset-frame", //refers to <div id="screenset-frame"> that will contain the screenset
      screenSet: "guldmaerker-LiteRegistration-guldmaerker-2020-11",
      onAfterScreenLoad: function (response) {
        const radios = document.querySelectorAll("input[type='radio']")
        radios.forEach((elm) => elm.setAttribute("name", "new_name"))
      },
      onAfterSubmit: async function (response) {
        try {
          const form = screensetFrameRef.current.querySelector("form")
          //get all input fields in the current instance of a screenset screen
          const email = form.querySelector("input[name='profile.email']").value
          const name = form.querySelector("input[name='profile.firstName']")
            .value
          const lastName = form.querySelector("input[name='profile.lastName']")
            .value
          const address = form.querySelector("input[name='profile.address']")
            .value
          const city = form.querySelector("input[name='profile.city']").value
          const zip = form.querySelector("input[name='profile.zip']").value
          const phone = form.querySelector(
            "input[name='profile.phones.default']"
          ).value
          /* const fotex =
            form.querySelector(
              "input[name='preferences.dk.foetex.newsletter.isConsentGranted']"
            ).value === "on" */
          const bilka =
            form.querySelector(
              "input[name='preferences.dk.bilka.newsletter.isConsentGranted']"
            ).value === "on"
          /* const salling =
            form.querySelector(
              "input[name='preferences.dk.salling.newsletter.isConsentGranted']"
            ).value === "on" */

          const svar1 = form.querySelector("input[value='1']:checked") !== null
          const svar2 = form.querySelector("input[value='2']:checked") !== null
          const svar3 = form.querySelector("input[value='3']:checked") !== null

          const today = new Date()
          const date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate()
          const time =
            today.getHours() +
            ":" +
            today.getMinutes() +
            ":" +
            today.getSeconds()

          const dateTime = date + " " + time
          const data = {
            name: name,
            lastname: lastName,
            address: address,
            email: email,
            city: city,
            zip: zip,
            phone: phone,
            fotex: false,
            bilka: bilka,
            salling: false,
            svar1: svar1,
            svar2: svar2,
            svar3: svar3,
            timestamp: dateTime,
          }

          if (svar1 || svar2 || svar3) {
            const integromatResponse = post(
              "https://hook.integromat.com/cnqs8o7mtsizjh92f9cnj8jwnl78pfi1",
              data
            )
            await integromatResponse
          } else {
            console.log("Data not sent")
          }
        } catch (e) {
          console.log(e)
        }
      },
      onBeforeValidation: function (response) {
        return new Promise(function (resolve) {
          let formData = response.formData
          let newsletters = [
            // formData["preferences.dk.foetex.newsletter.isConsentGranted"],
            formData["preferences.dk.bilka.newsletter.isConsentGranted"],
            // formData["preferences.dk.salling.newsletter.isConsentGranted"],
          ]
          if (newsletters.indexOf(true) === -1) {
            resolve({
              form: "Du skal vælge min. et nyhedsbrev",
              /* "preferences.dk.foetex.newsletter.isConsentGranted":
                "One is required", */
              "preferences.dk.bilka.newsletter.isConsentGranted":
                "One is required",
              /* "preferences.dk.salling.newsletter.isConsentGranted":
                "One is required", */
            })
          } else {
            resolve()
          }
        })
      },
      onFieldChanged: function (response) {
        if (
          /* document.querySelector(
            '[data-gigya-name="preferences.dk.foetex.newsletter.isConsentGranted"]:checked'
          ) */
          document.querySelector(
            '[data-gigya-name="preferences.dk.bilka.newsletter.isConsentGranted"]:checked'
          )
          /* document.querySelector(
            '[data-gigya-name="preferences.dk.salling.newsletter.isConsentGranted"]:checked'
          ) */
        ) {
          document.querySelector(".gigya-error-code-400009").style.display =
            "none"
        } else {
          const elm = document.querySelector(".gigya-error-code-400009")
          elm.style.display = "block"
          elm.classList.add("gigya-terms-error")
        }
      },
      onError: async function (response) {
        await response
      },
    })
  }

  useEffect(() => {
    if (
      screensetFrameRef.current !== null &&
      typeof window !== `undefined` &&
      window.gigya
    ) {
      setLoaded(true)
      loadScreenset()
    } else if (typeof window !== `undefined` && !window.gigya) {
      setLoaded(false)
      setTimeout(() => {
        setRetries((prev) => prev + 1)
      }, 100)
    }
  })

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://cdns.gigya.com/js/gigya.js?apikey=${process.env.GATSBY_GIGYA_API_KEY}`}
        ></script>
      </Helmet>
      <StyledCompetitionForm>
        <h3>Deltag her</h3>
        <div
          key="competition_form"
          id="screenset-frame"
          className="gigya-form"
          ref={screensetFrameRef}
        ></div>
      </StyledCompetitionForm>
    </>
  )
}

export default CompetitionForm

CompetitionForm.defaultProps = {}
CompetitionForm.propTypes = {
  children: PropTypes.node,
}
