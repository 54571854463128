import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { formatCurrency } from "@mostlycode/utils"

const StyledProductPrice = styled.span`
  font-size: 2.1rem;

  .price {
    .price__value {
      display: block;
      font-size: 1.3rem;
      line-height: 1.5;
      font-family: ${(props) => props.theme.fontStacks.body};

      &.-formatted {
        font-family: ${(props) => props.theme.fontStacks.heading};
        font-size: 2rem;
      }
    }
  }

  .price-splash {
    position: absolute;
    color: ${(props) => props.theme.colors.white};
  }
`

function ProductPrice({
  regular_price,
  current_price,
  current_price_formatted,
  currency,
  discount_text,
  ...props
}) {
  const regular_price_formatted = regular_price / 100
  const rebate_savings = regular_price - current_price
  const rebate_savings_formatted = rebate_savings / 100
  const rebate_pct = Math.round((rebate_savings / regular_price) * 100)
  return (
    <StyledProductPrice {...props}>
      <div className="price">
        <span className="price__value -formatted">
          {current_price_formatted},-
        </span>
        {rebate_pct > 0 && (
          <>
            <span className="price__value -rebate -pct">
              Rabat {rebate_pct}%
            </span>
            <span className="price__value -rebate -savings">
              Din Rabat {rebate_savings_formatted},-
            </span>
            <span className="price__value">
              Værdi: {regular_price_formatted},-
            </span>
          </>
        )}
      </div>
    </StyledProductPrice>
  )
}

export default ProductPrice

ProductPrice.defaultProps = {}
ProductPrice.propTypes = {
  children: PropTypes.node,
}
