import React, { useCallback, useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ResponsiveImage from "components/ResponsiveImage"
import { RellaxWrapper } from "react-rellax-wrapper"
import { resolveImage } from "utils/imageResolver"

const StyledParallaxBackground = styled.section`
  overflow: hidden;
  position: relative;
  height: 50vh;

  .bg {
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform ease-out 0s;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    height: ${props.visible_height};
    .bg {

    }
  `}
`

function ParallaxBackground({
  children,
  visible_height,
  image,
  imageSharp,
  index,
  ...props
}) {
  const resolvedImage = resolveImage(image, imageSharp)

  return (
    <StyledParallaxBackground style={{ height: visible_height }} {...props}>
      <RellaxWrapper className="bg" speed={-2} percentage={2}>
        <ResponsiveImage
          img={resolvedImage}
          imgProps={{
            style: {
              display: "block",
              width: "100%",
              height: "100vh",
              objectFit: "cover",
            },
          }}
        />
      </RellaxWrapper>
    </StyledParallaxBackground>
  )
}

export default ParallaxBackground

ParallaxBackground.defaultProps = {}
ParallaxBackground.propTypes = {
  children: PropTypes.node,
}
