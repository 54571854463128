import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import Content from "components/Content"
import { getIconFromUrl } from "utils/iconResolver"

const StyledFeature = styled.li`
  font-family: ${(props) => props.theme.fontStacks.heading};
  h3 {
    + p {
    }
  }

  svg {
    width: 100%;
    height: 6.5rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    h3 { }
  `}
`

function Feature({ feature_description, feature_icon, ...props }) {
  const icon = getIconFromUrl(feature_icon.url)
  return (
    <StyledFeature {...props}>
      <Icon icon={icon} size="sm" />
      <Content content={feature_description} />
    </StyledFeature>
  )
}

export default Feature

Feature.defaultProps = {}
Feature.propTypes = {}
