import React from "react"
import { graphql } from "gatsby"
import { Elements } from "prismic-reactjs"
import { valuable } from "utils/worthy"
import styled from "styled-components"
import Content from "components/Content"
import ProductPrice from "./Price"
import ResponsiveImage from "components/ResponsiveImage"
import { resolveImage } from "utils/imageResolver"

const StyledProduct = styled.li`
  font-size: 2.4rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 4rem;

  h3 {
    font-family: ${(props) => props.theme.fontStacks.bodyBold};
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.5;
  }

  p {
    margin: 0;
    font-size: 2.1rem;
  }

  .content {
    flex: 0 1 15rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0;
  `}
`

const StyledProductPrice = styled(ProductPrice)``

const StyledProductImage = styled.div`
  padding: 0;
  margin: 0;
  flex: 1 1 15rem;
  /* height: 30rem; */
  position: relative;
`

const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.heading1:
      return (
        <h3 key={key} className="title">
          {children}
        </h3>
      )
    default:
      return null
  }
}

function Product({ product, ...props }) {
  const {
    title,
    description,
    currency,
    regular_price,
    current_price,
    discount_text,
    product_image,
    imageSharp,
  } = product
  const image = resolveImage(product_image, imageSharp)
  const current_price_formatted = Math.round(current_price / 100)
  return (
    <StyledProduct className="product" {...props}>
      {product_image && (
        <StyledProductImage className="img">
          <ResponsiveImage img={image} />
        </StyledProductImage>
      )}
      <div className="content">
        {valuable(title) && (
          <Content content={title} serializer={htmlSerializer} />
        )}
        {/* {valuable(description) && <Content content={description} />} */}
        {current_price && currency && (
          <StyledProductPrice
            currency={currency}
            regular_price={regular_price}
            current_price_formatted={current_price_formatted}
            current_price={current_price}
            discount_text={discount_text}
          />
        )}
      </div>
    </StyledProduct>
  )
}

export default Product

Product.defaultProps = {}
Product.propTypes = {}

export const query = graphql`
  fragment ProductFragment on PRISMIC_Product {
    title
    description
    currency
    regular_price
    current_price
    discount_text
    product_image
    imageSharp: product_imageSharp @include(if: $isProduction) {
      childImageSharp {
        id
        desktop: fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
        mobile: fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    _meta {
      type
    }
  }
`
