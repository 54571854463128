import { valuable } from "./worthy"

const getIconFromUrl = (url) => {
  // Find the filename from the url and slice the guid and appended underscore to get the icon name
  return valuable(url)
    ? url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf(".")).slice(37)
    : null
}

export { getIconFromUrl }
