//returns false if input is worthless (null, undefined, Inifnity or NaN)
function valuable (input) {
  const worthless = [null, undefined, Infinity]
  if(Number.isNaN(input)) return false
  return(!
    worthless.some(value => {
      return value === input
    })
  )
}

//returns true if entity has length, or is a number / boolean type.
function worthy(entity) {
  if(valuable(entity)){
    const types = ['number', 'boolean']
    if(types.some( type => type == typeof(entity))) return true
    const keys = Object.keys(entity)
    return keys.length === 0 ? false : true
  }
  return false
}

//returns object
//filters obj by keys
function filterObj(obj, filter) {
  filter = typeof(filter) == 'string' ? [filter] : filter
  return (
    Object.entries(obj).reduce((acc, current) => {
      const [key, value] = current
      const include = valuable(filter) ? filter.some( x => x === key) : true
      return include ? {...acc, [key]: value} : {...acc} 
    }, {})
  )
}

//returns boolean
//Can check single value, or object.
//For objects, it will pass true if all keys are valuable
//For single values, it will pass true only if the entity is also worthy
//For checking if an object has any key-value pairs in the first place, use worthy instead
function isEmpty(entity, search) { 
  if (valuable(search)) entity = filterObj(entity, search)
  if(typeof(entity) == 'object') return Object.values(entity).some( x => !valuable(x))
  return !worthy(entity)
}

export { valuable, worthy, filterObj, isEmpty }