import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import slugify from "slugify"
import { Elements } from "prismic-reactjs"
import { Icon } from "@mostlycode/ui"
import Content from "components/Content"
import Feature from "./"

const StyledFeatureRepeater = styled.section`
  background: ${(props) => props.theme.colors.gray_light};
  color: ${(props) => props.theme.colors.black};
  padding: 4rem 2rem 6rem;
  position: relative;
  z-index: 100;
  .feature__list {
    margin: 0;
    list-style: none;
    padding: 0;
    li {
      list-style: none;
      text-align: center;
      padding: 2rem;
    }
  }

  svg {
    margin: 0 auto;
    width: 14.8rem;
    height: 14.8rem;
  }

  .inner__pre {
    text-align: center;
  }

  .inner_content {
    letter-spacing: 1.62px;
    font-size: 1.8rem;

    p:first-child {
      font-family: ${(props) => props.theme.fontStacks.bodyBold};

      color: #707070;
    }
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    .feature__list {
      padding: 4rem 0;
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    .feature__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2rem;
    }

    .inner {
      width: 85.1rem;
      margin: 0 auto;
    }

    .inner__pre {
      display: grid;
      grid-template-columns: 1fr;
      // grid-column-gap: 4rem;


      /* h2 {
        border-right: 1px solid rgba(255, 255, 255, 1);
        border-bottom: 1px solid rgba(255, 255, 255, 1);
        border-radius: 0 0 2rem 0;
        padding: 0 0 1rem;
      } */
    }

    .inner__after {
      text-align: center;
      max-width: 80rem;
      margin: 0 auto;
    }
  `}
`

const StyledDownloadLink = styled.a`
  margin: 1.5rem 1rem;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};
  line-height: 1.5;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.4px;
  font-size: 1.7rem;
  font-family: ${(props) => props.theme.fontStacks.heading};
  padding: 1rem 2rem;
  display: inline-block;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const htmlSerializer = (type, element, content, children) => {
  // Refer to this page for a full elements reference:
  // https://prismic.io/docs/javascript/beyond-the-api/html-serializer#7_0-example-with-all-elements

  switch (type) {
    case Elements.hyperlink:
      return (
        <StyledDownloadLink
          key="download_link"
          id="download_samlehaefte"
          href="/ka-haefte-low.pdf"
          target="_blank"
          rel="noreferrer noopener"
          forwardedAs="a"
        >
          {children}
        </StyledDownloadLink>
      )
    default:
      return null
  }
}

function FeatureRepeater({
  features_title,
  items,
  features_content,
  features_content_after,
  anchor_title,
  ...props
}) {
  const slug = slugify(anchor_title, {
    replacement: "-",
    lower: true,
    strict: false,
    locale: "da",
  })
  return (
    <StyledFeatureRepeater id={slug}>
      <div className="inner">
        <div className="inner__pre">
          <Icon icon="blomst" />
          <Content content={features_title} />
          <div className="inner_content">
            <Content content={features_content} />
          </div>
        </div>
        {items.length > 0 && (
          <ul className="feature__list">
            {items.map((item, i) => {
              return <Feature key={`feauture_${i}`} {...item} />
            })}
          </ul>
        )}
        {features_content_after && (
          <div className="inner__after">
            <Content
              content={features_content_after}
              serializer={htmlSerializer}
            />
          </div>
        )}
      </div>
    </StyledFeatureRepeater>
  )
}

export default FeatureRepeater

FeatureRepeater.defaultProps = {}
FeatureRepeater.propTypes = {}
