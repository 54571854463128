import React from "react"
import PropTypes from "prop-types"
import FeatureRepeater from "components/Feature/Repeater"
import ProductRepeater from "components/Product/Repeater"
import HeroSection from "./HeroSection"
import ParagraphImage from "./ParagraphImage"
import ParallaxBackground from "./ParallaxBackground"
import VideoRepeater from "./Video/Repeater"
import CustomHeroSection from "./CustomHeroSection"
import CustomProductsSection from "./CustomProductsSection"
import InstagramFeed from "./InstagramFeed"

function SliceZone({ ...props }) {
  const { slices } = props
  if (!slices || slices === null) return null
  const slice = slices.map((s, index) => {
    switch (s.type) {
      case "hero_section":
        return (
          <HeroSection
            key={`${s.type}_${index}`}
            items={s.fields}
            {...s.primary}
          />
        )
      case "custom_hero_section":
        return <CustomHeroSection key={`${s.type}_${index}`} {...s.primary} />
      case "custom_products":
        return (
          <CustomProductsSection
            key={`${s.type}_${index}`}
            items={s.fields}
            {...s.primary}
          />
        )
      case "instagram_feed":
        return (
          <InstagramFeed
            key={`${s.type}_${index}`}
            items={s.fields}
            {...s.primary}
          />
        )
      case "paragraph___image":
        return (
          <ParagraphImage
            key={`${s.type}_${index}`}
            content={s.primary.content}
            {...s.primary}
          />
        )
      case "feature_repeater":
        return (
          <FeatureRepeater
            key={`${s.type}_${index}`}
            items={s.fields}
            {...s.primary}
          />
        )
      case "product_repeater":
        return (
          <ProductRepeater
            key={`${s.type}_${index}`}
            items={s.fields}
            {...s.primary}
          />
        )
      case "video_repeater":
        return (
          <VideoRepeater
            key={`${s.type}_${index}`}
            items={s.fields}
            {...s.primary}
          />
        )
      case "parallax_background":
        return (
          <ParallaxBackground
            key={`${s.type}_${index}`}
            index={index}
            {...s.primary}
          />
        )
      default:
        console.warn(`No support for slice type ${s.type}`)
        return null
    }
  })
  return <>{slice}</>
}

export default SliceZone

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}
