import { isViewport } from "helpers"

const mapSrcToViewport = (src, viewport) => {
  if (
    isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) &&
    src.mobile
  ) {
    return src.mobile
  }
  return src.desktop
}

export { mapSrcToViewport }
