import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import slugify from "slugify"
import { Icon } from "@mostlycode/ui"
import Content from "components/Content"
import ResponsiveImage from "components/ResponsiveImage"
import Product from "./"
import { resolveImage } from "utils/imageResolver"
import { getIconFromUrl } from "utils/iconResolver"

const StyledProductRepeater = styled.section`
  padding: 2rem 2rem 4rem;
  position: relative;
  z-index: 100;
  background: ${(props) => props.theme.colors.white};
  .product__list {
    margin: 0;
    list-style: none;
    padding: 0;
    color: ${(props) => props.theme.colors.black};
    li {
      list-style: none;
      padding: 2rem;
    }
  }

  .range__icon {
    display: flex;
    align-items: center;
    svg {
      width: 22.4rem;
      height: 5.9rem;
    }

    &.-braun-logo-sort {
      svg {
        width: 20.5rem;
        height: 8.6rem;
      }
    }
  }

  .inner {
    h2 {
      font-size: 2.4rem;
      margin-bottom: 1em;
    }

    p {
      font-size: 2.1rem;
    }
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    padding: 2rem 2rem 4rem;
    .product__list {
      padding: 4rem 8rem;
      display: grid;
      grid-template-columns: repeat(${props.columns}, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;

      li {

      }
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    .inner {
      width: 96rem;
      margin: 0 auto;

      h2 {
        font-size: 4.1rem;
        letter-spacing: 3.69px;
        padding-left: 2rem;
      }

      p {
        font-size: 2.1rem;
      }
    }
    .product__list {
      grid-template-columns: repeat(${props.columns}, 1fr);
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    .inner {
      width: 104.0rem;
    }
  `}
`

function ProductRepeater({
  products_title,
  product_columns,
  items,
  products_content,
  anchor_title,
  theme_color,
  imageSharp,
  product_catalogue_image,
  product_catalogue_icon,
  ...props
}) {
  const image = resolveImage(product_catalogue_image, imageSharp)
  const icon = product_catalogue_icon
    ? getIconFromUrl(product_catalogue_icon.url)
    : null
  const slug = slugify(anchor_title, {
    replacement: "-",
    lower: true,
    strict: false,
    locale: "da",
  })
  return (
    <StyledProductRepeater id={slug} columns={product_columns}>
      <div className="inner">
        <div className="inner__pre">
          <Content content={products_title} />
          <Content content={products_content} />
        </div>
        {image && (
          <div className="product__catalogue__image">
            <ResponsiveImage img={image} />
          </div>
        )}
        <ul className="product__list" style={{ backgroundColor: theme_color }}>
          {icon && (
            <li className={`range__icon -${icon}`}>
              <Icon icon={icon} />
            </li>
          )}
          {items.map((item, i) => {
            return <Product key={`product_${i}`} {...item} />
          })}
        </ul>
      </div>
    </StyledProductRepeater>
  )
}

export default ProductRepeater

ProductRepeater.defaultProps = {}
ProductRepeater.propTypes = {}
