import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Slider from "react-slick"
import ResponsiveImage from "components/ResponsiveImage"
import { resolveImage } from "utils/imageResolver"

const StyledInstagramFeed = styled.section`
  padding: 2rem 2rem 4rem;

  .inner {
    border-radius: 4.4rem;
    margin: 0 auto 6.5rem;
    background: #dab2a92f;
    padding: 2rem 4rem;
  }

  .slider {
    padding: 0 2rem;
    margin-bottom: 4rem;
    /* max-width: 10%;
    margin: 0 auto; */

    button {
      &.slick-prev {
        left: -50px;
      }
      &.slick-next {
        right: -50px;
      }
      &:before {
        background: ${(props) => props.theme.colors.primary_accent};
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }

    .slide {
      border: 10px solid ${(props) => props.theme.colors.white};
    }
  }

  .stories {
    h2 {
      text-align: center;
      font-size: 1.5rem;
    }
  }

  .follow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .img {
      width: 16.9rem;
      height: 16.9rem;
    }

    p {
      margin-top: 2.2rem;
      margin-bottom: 2.2rem;
    }

    h2 {
      margin-bottom: 0;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`

    .inner {
      display: flex;
      width: 104rem;
      padding: 3rem 5rem;
    }

    .stories {
      flex: 0 1 33%;
      margin-left: 4rem;
      h2 {
        text-align: center;
        font-size: 1.5rem;
      }
    }

    .follow {
      flex: 1 1 66%;

      p {
        font-size: 1.8rem;
        letter-spacing: 1.62px;
      }
    }

    .slide {
      /* height: 60rem;
      width: 47.5rem; */
    }
  `}
`

const StyledLink = styled.a`
  margin: 0 0 2rem;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  border-radius: 4.6rem;
  line-height: 1.5;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.4px;
  font-size: 1.7rem;
  font-family: ${(props) => props.theme.fontStacks.bodyBold};
  padding: 1.5rem 2.5rem;
  display: inline-block;
  z-index: 20;
  position: relative;
`

function InstagramFeed({
  profile_photo,
  imageSharp,
  cta_button_text,
  items,
  cta_link: { url, target },
  cta_text,
  instagram_handle,
  ...props
}) {
  const authorImage = resolveImage(profile_photo, imageSharp)
  return (
    <StyledInstagramFeed>
      <div className="inner">
        <article className="stories">
          <h2>{`Stories fra @${instagram_handle}`}</h2>
          <div className="slider">
            <Slider>
              {items.map((item, i) => {
                const slideImage = resolveImage(item.story, null)
                return (
                  <div key={`slide_${i}`} className="slide">
                    <ResponsiveImage img={slideImage} />
                  </div>
                )
              })}
            </Slider>
          </div>
        </article>
        <article className="follow">
          <ResponsiveImage className="img" img={authorImage} />
          <h2>{`@${instagram_handle}`}</h2>
          <p>{cta_text}</p>
          <StyledLink href={url} target={target} rel="noopener noreferrer">
            {cta_button_text}
          </StyledLink>
        </article>
      </div>
    </StyledInstagramFeed>
  )
}

export default InstagramFeed

InstagramFeed.defaultProps = {}
InstagramFeed.propTypes = {
  children: PropTypes.node.isRequired,
}
