import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"

const StyledVideo = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .description {
    h3 {
      margin-bottom: 0;
    }

    p {
      margin-top: 0.25em;
    }
  }

  iframe {
    max-width: 100%;
    height: 26rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: none;
    display: block;

    iframe {
      max-width: none;
      width: 46rem;
      height: 27.8rem;
    }
  `}
`

function Video({ youtube_url, video_description, ...props }) {
  return (
    <StyledVideo {...props}>
      <div dangerouslySetInnerHTML={{ __html: youtube_url.html }} />
      <div className="description">
        <Content content={video_description} />
      </div>
    </StyledVideo>
  )
}

export default Video

Video.defaultProps = {}
Video.propTypes = {
  children: PropTypes.node,
}
