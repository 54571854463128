const resolveImage = (original, sharp) => {
  /* The original image from prismic is an object that contains the 'main' image crop, and has other crops as own properties of the 'main' image crop.
  The information we want from the original image, is the dimensions of each crop, which will be the width and height of the image parent container.
  We cannot use this information to conclude whether or not the image is fixed or fluid - that should be decided on a per use basis.
  */

  if (!original) return null

  const { mobile, desktop, ...main } = original

  /* Sharp only exists in production */
  if (sharp) {
    if (desktop) {
      sharp.childImageSharp.desktop.parentWidth = desktop.dimensions.width
      sharp.childImageSharp.desktop.parentHeight = desktop.dimensions.height
    } else {
      sharp.childImageSharp.desktop.parentWidth = main.dimensions.width
      sharp.childImageSharp.desktop.parentHeight = main.dimensions.height
    }
    if (mobile) {
      sharp.childImageSharp.mobile.parentWidth = mobile.dimensions.width
      sharp.childImageSharp.mobile.parentHeight = mobile.dimensions.height
    }
  }

  return {
    originalSrc: {
      desktop: desktop || main,
      mobile: mobile,
    },
    sharp: sharp
  }
}

export { resolveImage }
