import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import { default as DefaultParagraphImage } from "@mostlycode/ui/dist/Paragraphs/ParagraphImage"
import { Elements } from "prismic-reactjs"
import slugify from "slugify"
import Content from "components/Content"
import CompetitionForm from "components/CompetitionForm"
import ResponsiveImage from "components/ResponsiveImage"
import { resolveImage } from "utils/imageResolver"

const StyledWrapper = styled.div`
  padding: 4rem 2rem 4rem;
  margin: 0 2rem;
  background: ${(props) => props.theme.colors.gray_light};
  position: relative;
  z-index: 100;

  a {
    color: ${(props) => props.theme.colors.primary};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 8rem 4rem 4rem;
  `}

  ${(props) => props.theme.media.desktop_up`
      width: 104.0rem;
      margin: 0 auto;
  `}
`

const StyledParagraphImage = styled(DefaultParagraphImage)`
  position: relative;

  /* ${(props) => props.theme.media.tablet_portrait_up`
    grid-template-areas: "paragraph"
                         "image";
  `} */

  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

const StyledTitle = styled.h3`
  strong {
    font-family: ${(props) => props.theme.fontStacks.body};
    font-size: 4em;
    text-transform: uppercase;
    display: block;
    color: ${(props) => props.theme.colors.black};
    line-height: 0.6;
    letter-spacing: 6px;
    // transform: translate3d(-45px, 0, 0);
  }

  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

const StyledParagraph = styled.article`
  p {
    font-size: 1.8rem;
    letter-spacing: 1.62px;
  }

  h3 {
    font-size: 2.6rem;
    line-height: 1.15;
  }

  > svg {
    width: 30rem;
    height: 7.5rem;
    max-height: 8.3rem;
    margin: 0 auto;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 4rem 12rem;
    display: grid;
    grid-template-areas: "vind vind"
                         "intro_title_2 intro_title_2"
                         "intro_paragraph intro_paragraph"
                         "intro_paragraph_2 intro_paragraph_2"
                         "image_left paragraph_right_heading"
                         "image_left paragraph_right"
                         "paragraph_left_heading image_right"
                         "paragraph_left image_right"
                         "form form";

    > svg {
      grid-area: vind;
      /* width: 100%;
      height: 25%;
      max-height: 8.3rem; */
    }

    > h3 {
      line-height: 1.15;
      font-size: 2.6rem;

      &:nth-child(2) {
        text-align: center;
        grid-area: intro_title_2;
      }
    }

    p {
      &:nth-child(3) {
        grid-area: intro_paragraph;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
      }
      &:nth-child(4) {
        grid-area: intro_paragraph_2;
        text-align: center;
        // margin-top: 0;
        margin-bottom: 4rem;
      }
    }
  `}
`

const StyledImage = styled.div`

  /* grid-area: image;
  position: relative;
  z-index: 50;
  min-height: 40rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  ${(props) => props.theme.media.tablet_portrait_up`
    padding-top: 20rem;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    min-height: auto;
    margin-top: 45%;
    justify-content: center;
    align-items: flex-start;
    padding-top: 0;
  `} */
`

const StyledImageOverlay = styled.img`
  margin-top: 4rem;

  ${(props) => props.theme.media.tablet_portrait_up`
    max-width: 33rem;
    margin-top: 0;


    &:nth-of-type(1) {
      display: inline-block;
      margin-right: 2rem;
      grid-area: image_left;
      margin-top: 4rem;

      + h3 {
        grid-area: paragraph_right_heading;
        margin-top: 4rem;
        margin-bottom: 1rem;
        + p {
          margin-top: 1rem;
          grid-area: paragraph_right;
        }
      }
    }

    &:nth-of-type(2) {
      display: inline-block;
      margin-left: 2rem;
      grid-area: image_right;
      margin-top: 4rem;

      + h3 {
        grid-area: paragraph_left_heading;
        margin-top: 4rem;
        margin-bottom: 1rem;
        + p {
          margin-top: 1rem;
          grid-area: paragraph_left;
        }
      }
    }
  `}
  /* position: absolute;
  width: 28rem;
  right: ${(props) => (props.position === "right" ? "-2rem" : "auto")};
  left: ${(props) => (props.position === "left" ? 0 : "auto")};
  bottom: 10rem;

  ${(props) => props.theme.media.tablet_portrait_up`
    width: 39rem;
    right: 5rem;
    left: auto;
    bottom: 23rem;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    width: 34rem;
    right: ${props.position === "right" ? 0 : "auto"};
    left: ${props.position === "left" ? 0 : "auto"};
    top: -1rem;
  `} */
`

const StyledResponsiveImage = styled(ResponsiveImage)`
  ${(props) => props.theme.media.tablet_landscape_up`
  `}
`

const htmlSerializer = (
  type,
  element,
  content,
  children,
  content_image_positions
) => {
  // Refer to this page for a full elements reference:
  // https://prismic.io/docs/javascript/beyond-the-api/html-serializer#7_0-example-with-all-elements
  switch (type) {
    case Elements.image:
      return (
        <StyledImageOverlay
          key={`rte_${type}`}
          src={element.url}
          position={content_image_positions}
        />
      )
    case Elements.heading3:
      return <StyledTitle key="styled_title">{children}</StyledTitle>
    case Elements.strong:
      return <strong key="styled_bold">{content}</strong>
    case Elements.preformatted:
      return (
        <>
          <CompetitionForm key={`rte_${type}_competition_form`} />
        </>
      )
    default:
      return null
  }
}

function ParagraphImage({
  paragraph_image,
  imageSharp,
  content,
  content_image_positions,
  image_position,
  anchor_title,
  ...props
}) {
  const slug = slugify(anchor_title, {
    replacement: "-",
    lower: true,
    strict: false,
    locale: "da",
  })
  const resolvedImage = resolveImage(paragraph_image, imageSharp)
  return (
    <StyledWrapper>
      <StyledParagraphImage id={slug} flip {...props}>
        <StyledParagraph>
          <Icon icon="vind" />
          <Content
            content={content}
            serializer={(type, element, content, children) =>
              htmlSerializer(
                type,
                element,
                content,
                children,
                content_image_positions
              )
            }
          />
        </StyledParagraph>
        {paragraph_image && (
          <StyledImage>
            <StyledResponsiveImage
              img={resolvedImage}
              imgProps={{ objectFit: "contain" }}
            />
          </StyledImage>
        )}
      </StyledParagraphImage>
    </StyledWrapper>
  )
}

export default ParagraphImage

ParagraphImage.defaultProps = {}
ParagraphImage.propTypes = {
  content: PropTypes.array.isRequired,
  image: PropTypes.shape({
    fixed: PropTypes.object,
    fluid: PropTypes.object,
  }),
}
