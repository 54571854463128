import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ViewportContext } from "context"
import { isViewport } from "helpers"
import { mapSrcToViewport } from "./helper"
import Image from "components/Image"

const StyledImg = styled.figure`
  margin: 0;
  padding: 0;
  max-width: 100%;
  max-height: 100%;

  ${(props) =>
    props.isOriginal
      ? `
        /* width: 100%;
        height: 100%;
        img {
          width: 100%; height: auto; object-fit: cover;
        } */
      `
      : `
        /* width: 100%;
        height: 100%;
        img {
          width: 100%; height: auto; object-fit: cover;
        } */
      `}
`

function ResponsiveImage({ img, alt, imgProps, ...props }) {
  const viewport = useContext(ViewportContext)
  if (!img) return null
  let imgSrc
  // Valid for every environment that is not production
  if (!img.sharp) {
    imgSrc = mapSrcToViewport(img.originalSrc, viewport)
    return (
      <StyledImg
        style={
          {
            /* maxWidth: imgSrc?.dimensions.width,
          maxHeight: imgSrc?.dimensions.height, */
          }
        }
        isOriginal
        {...props}
      >
        <img src={imgSrc.url} alt={alt || imgSrc.alt} {...imgProps} />
      </StyledImg>
    )
  }

  imgSrc = mapSrcToViewport(img.sharp.childImageSharp, viewport)
  // This is fluid
  if (imgSrc.hasOwnProperty("aspectRatio")) {
    return (
      <StyledImg
        style={{
          width: "100%",
        }}
        {...props}
      >
        <Image fluid={imgSrc} alt={alt} {...imgProps} />
      </StyledImg>
    )
  }

  // Else it's fixed

  return (
    <StyledImg {...props}>
      <Image fixed={imgSrc} alt={alt} {...imgProps} />
    </StyledImg>
  )
}

export default ResponsiveImage

ResponsiveImage.defaultProps = {
  alt: "",
}
ResponsiveImage.propTypes = {
  img: PropTypes.object,
  alt: PropTypes.string,
}
